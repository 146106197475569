.container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 100vh;
  width: 100%;
  min-width: 320px;
}

.container_logo_and_buttons {
  display: flex;
  margin-top: auto;
  flex-direction: column;
  align-items: center;
}

.logo {
  > img {
    width: 140px;
  }
}

.buttons {
  margin-top: 20px;
}

.button {
  width: 100%;
  padding: 10px 0;
  box-sizing: border-box;
  margin: 10px 0;
  font-size: 16px;
  color: #1C8490;
  border: 2px solid #1C8490;
  border-radius: 5px;
  background-color: transparent;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.button:hover {
  background-color: #1C8490;
  color: #fff;
}

.footer {
  width: 100%;
  height: 180px;
  background: linear-gradient(139.21deg, #145f68 20.5%, #1C8490 100%);
  color: white;
  display: flex;
  justify-content: center;
  font-size: 14px;
  font-weight: bold;
  margin-top: auto;
}

.footer p {
  margin: 0;
  margin-top: 30px;
}
