.ipad-pro {
  background-color: #ffffff;
  display: flex;
  justify-content: center;
  width: 100%;
  min-height: 100vh;
}

.container_ipad-pro {
  background-color: var(--colors-bg-100);
  width: 90%;
  margin-top: 30px;
  max-width: 960px;
  height: auto;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.text-wrapper {
  width: 90%;
  max-width: 700px;
  font-weight: 600;
  color: #3f3849;
  font-size: 1.5rem;
  text-align: center;
  line-height: 1.4;
  font-style: normal;
}

.button_ipad-pro {
  all: unset;
  display: flex;
  width: 80%;
  max-width: 500px;
  height: 3rem;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  padding: 0.5rem 1rem;
  background-color: #fafafa;
  border-radius: 8px;
  cursor: pointer;
  transition: background 0.3s ease, color 0.3s ease;
}

.container_buttons {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 2rem;
}

.button_ipad-pro:hover {
  background: linear-gradient(130.13deg, #145f68 4.86%, #1C8490 92.48%);
}

.button_ipad-pro:hover .button-text {
  color: #fff;
}

.button_ipad-pro .button-active {
  background: linear-gradient(130.13deg, #145f68 4.86%, #1C8490 92.48%);
  color: #fff;
}

.button_ipad-pro.button-active .button-secondary .button-text {
  color: #fff;
}

.button-text {
  font-weight: 600;
  color: #94a3b8;
  font-size: 1rem;
  text-align: center;
  font-style: inherit;
}

.overlap-group {
  width: 90%;
  max-width: 600px;
  height: auto;
  margin: 2rem 0;
  border-radius: 12px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #f9f1f6;
  padding: 1rem;
}

.face-scan {
  width: 80%;
  max-width: 300px;
  height: auto;
  margin: 1rem 0;
}

.toque-aqui-para {
  width: 80%;
  max-width: 350px;
  text-align: center;
  font-weight: 600;
  color: #3f3849;
  font-size: 1rem;
  line-height: 1.4;
  font-style: inherit;
}

.email-input-container {
  > input {
    margin-top: 10px;
    margin-bottom: 20px;
    width: 355px;
    border: 1px solid #94a3b8;
    border-radius: 5px;
    padding: 5px;
    box-sizing: border-box;
  }
}

@media (min-width: 768px) {
  .text-wrapper {
    font-size: 20px;
    margin: 0px;
  }

  .button_ipad-pro {
    height: 35px;
    width: 100%;
  }

  .button_ipad-pro:hover .button-secondary {
    background: linear-gradient(130.13deg, #145f68 4.86%, #1C8490 92.48%);
    color: #fff;
  }

  .button_ipad-pro.button-active .button-secondary {
    background: linear-gradient(130.13deg, #145f68 4.86%, #1C8490 92.48%);
    color: #fff;
  }

  .button_ipad-pro.button-active .button-text .button-secondary {
    color: #fff;
  }

  .button-text {
    font-size: 1.2rem;
  }

  .toque-aqui-para {
    font-size: 1.2rem;
  }
}

.camera-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 1rem 0;
}

.camera-container .webcam,
.camera-container .preview-selfie {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.camera-container .webcam {
  max-width: 100%;
  height: auto;
}

@media (max-width: 600px) {
  .camera-container .webcam {
    width: 250px;
    height: 250px;
  }
}

@media (min-width: 601px) and (max-width: 1024px) {
  .camera-container .webcam {
    width: 370px;
  }
}

@media (min-width: 1025px) and (max-width: 1366px) {
  .camera-container .webcam {
    width: 450px;
    height: 450px;
    border-radius: 5px;
  }

  .button_ipad-pro {
    margin-bottom: 20px;
  }
}

@media (min-width: 1367px) {
  .camera-container .webcam {
    width: 650px;
    height: 600px;
  }
}
