.success-checkin-container {
  background: linear-gradient(130deg, #145f68 4.86%, #1C8490 92.48%);
  color: #fff;
  text-align: center;
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.check-icon {
  font-size: 5rem;
  width: 80px;
  height: 80px;
  margin-bottom: 1rem;
}

.success-text {
  font-size: 1.5rem;
  font-weight: bold;
}

.return-button {
  margin-top: 10px;
  background-color: #1C8490;
  color: #94a3b8;
  color: #fff;
  border: 1px solid #fff;
  border-radius: 5px;
  outline: none;
  padding: 5px;
  font-weight: bold;
  display: inline-flex;
  cursor: pointer;
  font-size: 16px;
  align-items: center;
  justify-content: center;
}

.return-button:focus,
.return-button:active {
  outline: none;
}

.return-button:hover {
  background-color: #25b2c2;
}
