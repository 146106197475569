* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
}

body,
html {
  height: 100%;
}

.checkin-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  justify-content: space-between;
  align-items: center;
}

.checkin-header {
  background: linear-gradient(107.8deg, #145f68 10%, #1C8490 74.53%);
  height: 230px;
  width: 100%;
}

.icon-wrapper {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 130px;
  background: linear-gradient(133.46deg, #145f68 15.97%, #1C8490 88.08%);
  border-radius: 80px;
  width: 140px;
  height: 150px;
  margin-left: auto;
  margin-right: auto;

  > img {
    width: 70px;
  }
}

.checkin-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 50px;
  box-sizing: border-box;

  > p {
    color: #3f3849;
    font-weight: bold;
    margin-bottom: 20px;
  }

  > input {
    width: 300px;
    border: 1px solid #94a3b8;
    border-radius: 5px;
    padding: 5px;
    box-sizing: border-box;
  }
}

.continue-button {
  margin-top: 50px;
  background-color: transparent;
  color: #94a3b8;
  border: none;
  outline: none;
  padding: 0;
  font-weight: bold;
  display: inline-flex;
  cursor: pointer;
  font-size: 16px;
  align-items: center;
  justify-content: center;
}

.continue-button:focus,
.continue-button:active {
  outline: none;
}

.checkin-footer {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  padding: 5px 20px;
  box-sizing: border-box;
  font-size: 14px;
  color: #94a3b8;

  > p {
    font-size: 15px;

    font-style: italic;

    > span {
      font-weight: 600;
    }
  }
}
